#footer {
    position: fixed;
    width: 100%;
    height: 64px;
    left: 0px;
    bottom: 0px;
    background: #fff;
    border-radius: 4px;
    z-index: 2;
}
ul.footerList {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}
ul.footerList li {
    width: 20%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #5F6C7B;
}
ul.footerList li.createLabel {
    margin-top: 27px;
}
button.circleButton {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
#footer button.createButton {
    position: absolute;
    left: calc(50% - 32px);
    bottom: 32px;
    font-size: 30px;
}
#footer button.createButton.close {
    font-size: unset;
    color: #0066CC;
    background-color: #ffffff;
}
.overlay {
    position: fixed;
    text-align: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(24, 70, 120, 0.65) -7.26%, #003366 102.9%);
    border-radius: 4px;
    z-index: 1;
}