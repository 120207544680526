.modal-overlay{
    position: fixed;
    z-index: 2000;
    top: 0%;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background:rgba(0,0,0,0.75);
}
.modal{
    z-index: 2001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fafafa;
    padding: 0;
    max-height: 70%;
    min-width: 300px;
    max-width: 500px;
    border-radius: 4px;
    box-shadow: 3px 3px 4px rgba(0,0,0,0.3);
}
.modal-content{
    padding: 20px;
}
.modal-footer{
    text-align: right;
    padding: 12px;
}
.modal-footer button{
    width: 40%;
}
.modal-close{
    position: absolute;
    right: 4px;
    top: 4px;
    background: none;
    border: none;
    font-size: 14px;
    color: #999;
}
.btn-modal:after{
    display: none;
}
.modal .btn-modal-confirm{
    color: #FFF;
}
.modal .btn-modal-cancel{
    color: #FFF;
}