button{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: bold;
}
.btn-wrapper{
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}
.flat-button{
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 14px;
    border: none;
    background: #00685b;
    color: #FFF;
    font-weight: bold;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;
    transition: color .3s;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
    text-transform: uppercase;
}
.flat-button:disabled{
    background: #ececec;
    color: #777;
}
.flat-button.active{
    background: #439688;
    box-shadow: none;
    /* border: 2px solid #FFF; */
}
.flat-button[disabled]{
    box-shadow: none;
}
/* .flat-button:after {
    position: absolute;
    top: 90%; left: 0;
    width: 100%; height: 100%;
    background: #439688;
    content: "";
    z-index: -2;
    transition: transform .3s;
} */
.flat-button:disabled:after,
.flat-button.active:after{
    display: none;
}

.flat-button:hover::after {
    transform: translateY(-89%);
    transition: transform .3s;
}