.afterCard {
    margin: 15px auto;
    padding: 10px;
    position: relative;
    width: 50%;
    text-align: center;
    background: #ececec;
    display: flex;
    align-items: center;
}
.afterCard:has(.icon-event) {
    padding: 0;
}
.icon-event{
    display: flex;
    padding: 10px;
    border-radius: 0 4px 0 0;
    background: #0796F2;
}
.playcardFirst .icon-event{
    background: #0796F2;
}
.playcardTd .icon-event{
    background: #3DCD7F;
}
.playcardError .icon-event{
    background: #EF6277;
}
.playcard{
    position: relative;
    background: '#FFF';
    width: 56%;
    z-index: 3;
}
.playcardTd {
    width: 50%;
    margin: 15px auto;
    position: relative;
    text-align: center;
    background: #ececec;
    border-radius: 8px;
}
.playcardFirst {
    width: 50%;
    margin: 15px auto;
    padding: 8px;
    position: relative;
    text-align: center;
    background: #ececec;
    border-radius: 8px;
}
.playcardWarning {
    border: 2px solid;
}
.time{
    position: absolute;
    right: 12%;
    top: -20px;
    font-size: 0.8rem;
}
.playOfense time{
    left: 11%;
}
.down {
    font-weight: 500;
}
.playcard.play-defense {
    margin-left: 44%;
}
.playcards-list {
    position: relative;
}
.playcard.play-defense .play-timer {
    margin-left: 20%;
}
.playcards-list:before {
    position: absolute;
    content: "";
    left: 50%;
    top: 0;
    height: 100%;
    z-index: 0;
    border: 0.5px dashed #9DB3CC;
}