.gameplay{
    padding-bottom: 60px;
}

.question_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #FFF;
    animation: fade 0.7s 1;
    padding: 10px;
    margin-bottom: 12px;
}
.question_wrapper:nth-child(1){
    margin-top: 10px
}
.question_wrapper:last-of-type{
    margin-bottom: 45px;
}
.question_wrapper .question{
    width: 90%;
    border-radius: 20px;
    position: relative;
    padding: 20px;
    justify-content: space-between;
}

.question_wrapper .btn-wrapper{
    flex-basis: 50%;
    flex-grow: 0;
    padding: 5px;
    overflow: hidden;
}
.question_wrapper .btn-wrapper.btn-type-number {
    flex-basis: 100%;
}
.question_wrapper .skip {
    flex-basis: 100%;
}
.question_wrapper .skip .btn{
    background: none;
    box-shadow: none;
    color: #00685b;
}

button.btn-finish-play{
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 0;
    width: 100%;
    top: auto;
    border-radius: 0;
    box-shadow: 0 -1px 2px rgba(0,0,0,0.3);
}
.btn-finish-play:after{
    content: unset;
    display: none;
}
@-webkit-keyframes fade { from { opacity:0; } to { opacity:1; }  }
   @-moz-keyframes fade { from { opacity:0; } to { opacity:1; }  }
     @-o-keyframes fade { from { opacity:0; } to { opacity:1; }  }
        @keyframes fade { from { opacity:0; } to { opacity:1; }  }